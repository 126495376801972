import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{}>;

export const FooterGroupLink = ({ children }: Props) => {
  return (
    <div className={clsx('footer-group-link', 'flex flex-col items-center lg:items-start')}>
      {children}
    </div>
  );
};
