import DMCA from '@/shared/components/DMCA';
import { VERSION } from '@/constants/envConfig';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
};

export const VersionAndDMCA = ({ className }: Props) => {
  return (
    <div className={twMerge('my-[15px] flex items-center gap-[20px] xll:gap-[15px]', className)}>
      <p className="version text-[14px] md:text-[20px]" style={{ color: '#8a8a8a' }}>
        {VERSION || 'VERSION'}
      </p>
      <DMCA />
    </div>
  );
};
