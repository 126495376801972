import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from 'twin.macro';
import { getConfigByKey } from '@/shared/features/tenantConfig/services';
import { FooterLink } from './components/FooterLink';
import { FooterGroupLink } from './components/FooterGroupLink';
import { VersionAndDMCA } from './components/VersionAndDMCA';
import { useSelector } from 'react-redux';
import { pathToUrl } from '@/shared/utils/routes';
import { routePageNames } from '@/core/routes';
import appStoreIconUrl from '@/assets/img/appStoreIcon.svg?url';
import googlePlayIcon from '@/assets/img/googlePlayIcon.svg?url';

const privacyPolicyLink = getConfigByKey('privacyPolicyLink');
const termsOfUseLink = getConfigByKey('termsOfUseLink');

const StyledComp = styled.div`
  padding-bottom: 1rem;
  .footer {
    &--term {
      /* margin-top: 1rem; */
      /* a, */
      p {
        text-transform: uppercase;
        color: ${({ theme }) => theme.primaryColor};
      }
    }
  }
  .version {
    margin: 0;
  }
  @media (min-width: 768px) {
    padding-top: 30px;
    .footer {
      &--layout {
        padding: 1rem 45px;
      }
    }
  }
`;

function FooterSctv() {
  const { t } = useTranslation();
  const documentPages = useSelector(state => state?.tenantConfig?.doc_page_infos ?? []);

  return (
    <StyledComp className="footer--row">
      <div className="footer--layout flex">
        <div className="mt-[16px] flex-grow-[1] lg:mt-0">
          <div className="footer--term">
            <div className="grid grid-cols-1 xll:grid-cols-[repeat(2,minmax(290px,1fr))] xll:gap-[50px] ">
              <FooterGroupLink>
                <FooterLink
                  // target="_blank"
                  to={privacyPolicyLink}
                >
                  {t('subscriptions.privacy policy')}
                </FooterLink>
                {/* <p style={{ paddingLeft: 10, paddingRight: 10, color: '#555555' }}>|</p> */}
                <FooterLink
                  // target="_blank"
                  to={termsOfUseLink}
                >
                  {t('subscriptions.box streaming')}
                </FooterLink>
              </FooterGroupLink>
              <FooterGroupLink>
                {documentPages.map(docPage => (
                  <FooterLink
                    key={docPage.id}
                    // target="_blank"
                    to={pathToUrl(routePageNames.documentPage, { documentSlug: docPage.slug })}
                  >
                    {(docPage.title ?? '').toLowerCase()}
                  </FooterLink>
                ))}
              </FooterGroupLink>
            </div>
          </div>
          <VersionAndDMCA className="flex-col lg:hidden" />
        </div>
        <div style={{ textAlign: 'left' }}>
          <div className="footer--contact">
            <p>
              <Link to="/help">{t('subscriptions.footer.ContactUs')} </Link>
            </p>
            <p>
              <i className="fa fa-phone" aria-hidden="true" />
              <span> : </span>
              <span className="footer--contact-bold">19001878</span>
            </p>
            <p>
              <a className="footer--mailto" href="mailto:sctvonline@sctv.vn">
                <i className="fa fa-envelope-o" aria-hidden="true"></i>
                <span> : </span>
                <span className="footer--contact-bold">sctvonline@sctv.vn</span>
              </a>
            </p>
          </div>
          <div className="footer--version">
            <p>
              <i className="fa fa-copyright" aria-hidden="true" /> Số ĐKKD: 0301463315 do Sở KHĐT
              TP.HCM cấp ngày 09/04/2010
            </p>
            <p>31-33 Đinh Công Tráng, P. Tân Định, Q.1, TP.HCM</p>
          </div>
          <div className="footer--download">
            <a
              target="_blank"
              href="https://apps.apple.com/vn/app/sctv/id1564652065"
              rel="noreferrer"
            >
              <img src={appStoreIconUrl} />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=io.vimai.sctvonline"
              rel="noreferrer"
            >
              <img src={googlePlayIcon} />
            </a>
          </div>
          <VersionAndDMCA className="hidden  justify-end lg:!flex" />
        </div>
      </div>
    </StyledComp>
  );
}

export default memo(FooterSctv);
