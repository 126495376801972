//@ts-nocheck
import { Link, LinkProps } from 'react-router-dom';

type Props = LinkProps;

export const FooterLink = ({ to = '#', children, ...rest }: Props) => {
  return (
    <Link
      className="font-open-sans text-[11px] font-medium capitalize leading-[30px] text-[#cdcdcd] md:text-[20px] md:leading-[45px]"
      to={to}
      {...rest}
    >
      {children}
    </Link>
  );
};
