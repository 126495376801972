import React from 'react';
import { styled, theme } from 'twin.macro';
import dmcaIconPath from '@/assets/icons/dmca-icon.svg?url';

const DMCAStyled = styled.a`
  display: inline-block;
  /* margin-top: 15px; */
  font-family: Open Sans;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  line-height: 31px;
  text-transform: uppercase;
  text-align: right;
  font-weight: 900;
  &:hover,
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    color: #ffffff;
    border: none;
    outline: none;
  }
  span {
    padding: 5px 5px;
    border: 2px solid #ffffff;
    &:first-of-type {
      background-color: #8dc642;
      border-right: none;
    }
    &:last-of-type {
      background-color: #221e20;
    }
  }
  @media (min-width: ${theme('screens.lg')}) {
    font-size: 15px;
  }
`;
function DMCA() {
  return (
    <DMCAStyled
      href="//www.dmca.com/Protection/Status.aspx?ID=3c92a888-7e7e-4d96-832f-ddd98cca7363"
      title="DMCA.com Protection Status"
      className="dmca-badge"
    >
      {/* <img src={dmcaIconPath} alt="DMCA.com Protection Status" /> */}

      <span>DMCA</span>
      <span>protected</span>
    </DMCAStyled>
  );
}

export default DMCA;
